<template lang="pug">
  .wrapper
    v-form(v-model="passwordUpdateValid", ref="passwordUpdateForm", @submit.prevent="updatePassword")
      v-layout.security__settings(justify-space-between, align-start)
        v-flex.security__settings__field(xl2, lg2, md2, sm12, xs12) {{ $t('formFields.password') }}
        v-flex.security__settings__main(xl6, lg6, md6, sm9, xs12)
          v-text-field.security__settings__main__input(
            v-if="isPasswordSet",
            v-model="currentPassword",
            :label="$t('formFields.passwordCurrent')",
            type="password",
            :rules="[rules.passwordLength, rules.max, rules.currentPassword]",
            @input="onCurrentPasswordInput")
          v-text-field.security__settings__main__input(
            :label="$t('formFields.passwordNew')",
            v-model="newPassword",
            type="password",
            :rules="[rules.password]",
            @input="onNewPasswordInput")
          v-text-field.security__settings__main__input(
            :label="$t('formFields.passwordConfirm')",
            v-model="newPasswordAgain",
            type="password",
            :error-messages="passwordMatchError()",
            @input="onNewPasswordInput")
          .form_success(v-if="passwordUpdateSuccess") {{ passwordUpdateSuccess }}
          .form_error(v-if="passwordUpdateErrors.length", v-for="(error, index) in passwordUpdateErrors", :key="'error_'+index") #[strong {{ error.title }}] #[span(v-html="error.detail")]
          v-btn(:disabled="!passwordUpdateValid && !loading", @click="updatePassword") {{ $t('actions.save') }}
        v-flex.security__settings__help(xl3, lg3, md3, sm12, xs12) {{ $t('messages.inform.passwordChange', { min: 8 }) }}
      v-layout.security__settings.security__settings__two__factor(justify-space-between, align-center)
        v-flex.security__settings__field.two_fa(xl2, lg2, md2, sm12, xs12) {{ $t('common.twoFA') }}
        v-flex.security__settings__main(xl6, lg6, md6, sm12, xs12)
          v-btn(v-if="!twoFactorAuth", :disabled="!enable2FABtnActive", @click="show2FADialog = true") {{ $t('actions.enable') }}
          span.ml-10.font-weight-medium(v-if="!twoFactorAuth && !enable2FABtnActive") {{ $t('2FA.willBeAvailableIn', { time: time2FATxt }) }}
          .d-flex.align-center(v-if="twoFactorAuth")
            v-icon.mr-2(size="18", color="#66C00D") mdi-check
            span.mr-14.font-weight-600 {{ user['2fa_type'] === 1 ? $t('2FA.googleEnabled') : $t('2FA.emailEnabled') }}
            .main_link.disable(@click="show2FADialog = true") {{ $t('actions.disable') }}
        v-flex.security__settings__help(xl3, lg3, md3, sm12, xs12) {{ $t('2FA.hint') }}
      v-layout.security__settings.security__settings__sessions(justify-space-between, align-start)
        v-flex.security__settings__field(xl2, lg2, md4, sm12, xs12) {{ $t('hints.openSessions') }} #[span.sessions__number {{ sessionsTotal }}]
        v-flex.security__settings__main(xl6, lg6, md8, sm12, xs12)
          .session_group(v-for="(sessions, groupId) in sessionGroups", :key="groupId", :class="{deleting: deleting === groupId}")
            .session_group__close
              span.main_link(@click="deleteSessionGroup(groupId)") {{ $t('actions.closeSessionGroup') }}
            v-layout.session(
              justify-space-between,
              v-for="session in sessions",
              :key="session.id")
              SvgScreen.session__svg(v-if="session.device === 'Computer'")
              SvgiPhoneApp.session__svg(v-if="session.device === 'Mobile App' && session.browser.toLowerCase() === 'iphone'")
              SvgAndroidApp.session__svg(v-if="session.device === 'Mobile App' && session.browser.toLowerCase() === 'android'")
              v-layout.session__details(column, :class="{ active: session.service.id === '0' && groupId === currentSession }")
                .location {{ servicesNames[session.service.id ]}}
                .location {{ session.device }}, {{ session.country }} #[span.location__ip IP {{ session.ip }}]
                .location__agent {{ session.browser }} | {{ sessionCreated(session.created_at, $i18n.locale) }}
        v-flex.security__settings__help(xl3, lg3, md3, sm12, xs12)
    v-dialog.dialog(v-model="show2FADialog", fullscreen)
      v-card
        SettingsTwoFactorAuth(:connected="twoFactorAuth", @clickClose="show2FADialog = false")
</template>

<script>
import SvgScreen from '@/components/svg/SvgScreen';
import SvgiPhoneApp from '@/components/svg/SvgiPhoneApp';
import SvgAndroidApp from '@/components/svg/SvgAndroidApp';
import store from '@/store/store';
import CONSTANTS from '@/constants';
import REGEXP from '@/regexp';
import { USER_LOGOUT } from '@/store/modules/user/types';
import SettingsTwoFactorAuth from '@/components/SettingsTwoFactorAuth';
import { session } from '@/dates';

export default {
  components: {
    SvgScreen,
    SettingsTwoFactorAuth,
    SvgiPhoneApp,
    SvgAndroidApp
  },
  props: {
    emailConfirmed: { type: Boolean, required: true }
  },
  data() {
    return {
      loading: false,
      passwordUpdateValid: false,
      emailUpdateValid: false,
      passwordUpdateErrors: [],
      passwordUpdateSuccess: '',
      currentPassword: '',
      currentPasswordValid: true,
      currentSession: localStorage.getItem('session_id'),
      newPassword: '',
      newPasswordAgain: '',
      passwordNotConfirmed: true,
      emailChangePassword: '',
      newEmail: '',
      sessionGroups: {},
      sessionsTotal: 0,
      servicesNames: CONSTANTS.SERVICES_NAMES,
      deleting: '',
      twoFactorAuth: false,
      show2FADialog: false,
      isPasswordSet: false,
      interval2FA: null,
      time2FALeft: 0
    };
  },
  methods: {
    sessionCreated(date, locale) {
      return session(date, locale);
    },
    onCurrentPasswordInput() {
      if (!this.currentPasswordValid) this.currentPasswordValid = true;
      if (this.passwordUpdateErrors.length) this.passwordUpdateErrors = [];
      if (this.passwordUpdateSuccess) this.passwordUpdateSuccess = '';
    },
    onNewPasswordInput() {
      if (this.passwordUpdateErrors.length) this.passwordUpdateErrors = [];
      if (this.passwordUpdateSuccess) this.passwordUpdateSuccess = '';
    },
    passwordMatchError() {
      if (this.newPassword.length < 8) return '';

      if (this.newPassword.length >= 8 && !this.newPasswordAgain) return this.$t('validation.required');

      return (this.newPassword === this.newPasswordAgain) ? '' : this.$t('validation.passwordsDoNotMatch');
    },
    updatePassword() {
      if (this.$refs.passwordUpdateForm.validate()) {
        this.loading = true;
        this.passwordUpdateErrors = [];

        const payload = {
          data: {
            type: 'update-passwords',
            attributes: {
              new_password: this.newPassword,
              confirm: this.newPasswordAgain
            }
          }
        };

        if (this.isPasswordSet) {
          payload.data.attributes.old_password = this.currentPassword;
        }

        this.$store.dispatch('user/updatePassword', payload)
          .then(async () => {
            this.passwordUpdateErrors = [];
            this.passwordUpdateSuccess = this.$i18n.t('messages.success.passwordUpdated');
            this.$refs.passwordUpdateForm.resetValidation();
            this.currentPassword = '';
            this.newPassword = '';
            this.newPasswordAgain = '';
            await this.$store.dispatch('user/getUser');
            this.getUserSessions();
          })
          .catch(errors => {
            console.log('Errors in updating the password: ', errors);
            errors.forEach(error => {
              if (error.code === 9) {
                this.currentPasswordValid = false;
                this.$refs.passwordUpdateForm.validate();
              } else {
                this.passwordUpdateErrors.push(error);
              }
            });
          })
          .finally(() => { this.loading = false; });
      }
    },
    getUserSessions() {
      this.$store.dispatch('user/getUserSessions')
        .then(sessions => {
          this.currentSession = localStorage.getItem('session_id');
          this.sessionsTotal = sessions.length;
          this.sessionGroups = {};

          sessions.forEach(item => {
            if (!this.sessionGroups[item.group.id]) {
              this.sessionGroups[item.group.id] = [];
            }

            this.sessionGroups[item.group.id].push(item);
          });
        })
        .catch(err => console.log('Error in getting user\'s sessions', err));
    },
    deleteSessionGroup(groupId) {
      this.deleting = groupId;

      this.$store.dispatch('user/deleteSessionGroup', groupId)
        .then(() => {
          if (groupId === this.currentSession) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('session_id');
            localStorage.removeItem('referrer');

            this.$store.commit(`user/${USER_LOGOUT}`);

            this.$router.push('/');
          }

          delete this.sessionGroups[groupId];

          this.sessionsTotal = Object.values(this.sessionGroups).reduce((acc, current) => acc + current.length, 0);
        })
        .catch(err => console.log(err))
        .finally(() => { this.deleting = ''; });
    },
    setTime2FALeft() {
      if (this.interval2FA) clearInterval(this.interval2FA);

      if (Date.parse(this.user.allow_to_turn_on_2fa_at) > Date.now()) {
        this.time2FALeft = Date.parse(this.user.allow_to_turn_on_2fa_at) - Date.now();

        this.interval2FA = setInterval(() => { this.time2FALeft -= 1000; }, 1000);
      }
    }
  },
  async mounted() {
    await this.getUserSessions();

    this.setTime2FALeft();
  },
  computed: {
    user() { return this.$store.state.user; },
    rules() {
      return {
        email: v => /\w+@\w+\.\w{2}/.test(v) || this.$t('validation.emailInvalid'),
        password: v => REGEXP.password.test(v) || this.$t('validation.password', { min: 8 }),
        passwordLength: v => v.length >= 8 || this.$t('validation.passwordLength', { min: 8 }),
        currentPassword: () => this.currentPasswordValid || this.$t('validation.currentPasswordIsWrong'),
        passwordMatch: v => (REGEXP.password.test(v) && v === this.newPassword) || this.$t('validation.passwordsDoNotMatch'),
        required: v => !!v || this.$t('validation.required'),
        max: v => v.length <= 160 || this.$t('validation.maxLength', { length: 160 })
      };
    },
    enable2FABtnActive() {
      return this.emailConfirmed && this.time2FALeft <= 0;
    },
    time2FATxt() {
      if (this.time2FALeft <= 0) return '';

      const seconds = Math.floor((this.time2FALeft / 1000) % 60);
      const minutes = Math.floor((this.time2FALeft / 1000 / 60) % 60);
      const hours = Math.floor((this.time2FALeft / (1000 * 60 * 60)));

      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
  },
  watch: {
    user: {
      handler(val) {
        this.twoFactorAuth = val.is_2fa_enabled;
        this.newEmail = val.email;
        this.isPasswordSet = val.is_password_set;

        if (val.allow_to_turn_on_2fa_at) {
          this.setTime2FALeft();
        }
      },
      deep: true
    },
    show2FADialog(val) {
      if (val) this.$vuetify.goTo('.settings_menu');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.twoFactorAuth = store.state.user.is_2fa_enabled;
      vm.newEmail = store.state.user.email;
      vm.isPasswordSet = store.state.user.is_password_set;
    });
  },
  beforeDestroy() {
    clearInterval(this.interval2FA);
  }
};
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    .wrapper { margin-top: 60px; }

    .security__settings {
      @media screen and (max-width: 980px) { flex-wrap: wrap; }

      &__field {
        color: $sub-black;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin-top: 16px;

        &.two_fa { margin-top: 0px; }

        .sessions__number { color: $grey-text; }

        @media screen and (max-width: 980px) {
          margin-bottom: 10px;
          order: 1;
          text-align: left;
        }
      }

      &__main {
        text-align: left;

        .main_link.disable {
          display: inline-block;
          font-size: 14px !important;
          font-weight: normal !important;
          white-space: nowrap;
        }

        @media screen and (max-width: 980px) { order: 3; }
        .v-input--checkbox { margin: 0 !important; }

        &__input {
          margin: 0 !important;
          width: 300px !important;

          &.two__factor {
            width: 550px !important;
            ::v-deep input { margin: auto 0; }
            &.filled { background-color: $light-theme-line !important; }
            @media screen and (max-width: 980px) { width: auto !important; }
          }

          &.email__input {
            width: 400px !important;
            @media screen and (max-width: 980px) { width: auto !important; }
          }
        }
      }

      &__help {
        color: $grey-text;
        font-size: 12px;
        text-align: left;

        @media screen and (max-width: 980px) {
            font-size: 14px;
            margin: 20px 0;
            order: 2;
        }
      }

      &__email { margin-top: 60px; }
      &__two__factor { margin: 50px 0; }

      &__sessions {
        .session_group {
          border-bottom: 1px solid $grey-light;
          margin-bottom: 20px;
          padding-bottom: 20px;

          &__close {
            text-align: right;

            .main_link {
              text-align: right;
              font-size: 14px !important;
              font-weight: normal !important;
              white-space: nowrap;
            }
          }

          &.deleting { opacity: .5; }
        }

        .session {
          margin-bottom: 20px;
          width: 550px;
          @media screen and (max-width: 980px) { width: auto !important; }

          svg.session__svg {
            height: 42px;
            width: 66px;
            & > path { fill: $black; }
          }

          &__details {
            font-size: 16px;
            font-weight: 600;
            margin-left: 28px;
            position: relative;
            text-align: left;

            &.active::before {
              background-color: $green;
              border-radius: 50%;
              content: '';
              height: 8px;
              left: -13px;
              position: absolute;
              top: 6px;
              width: 8px;
            }

            .location {
              color: $sub-black;
              &__ip { color: $grey-text; }

              &__agent {
                color: $grey-text;
                font-size: 12px;
              }
            }
          }
        }
      }

      &__delete {
        color: $light-theme-text !important;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .save_action {
      background-color: $blue !important;
      border-radius: 2px;
      width: 130px;
      height: 60px;
      padding: 21px 0 25px;
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      color: $white;
      text-align: center;
      text-transform: none;

      @media screen and (max-width: 468px) { width: 100%; }

      ::v-deep .v-btn__content {
        font-size: 18px !important;
        line-height: normal !important;
        text-align: center;
        text-transform: none;
      }
    }

    .form_error,
    .form_success {
      text-align: left;
      margin: 10px 0;
    }

    .form_error { color: $error-color; }

    @media screen and (max-width: 1024px) {
      .wrapper { max-width: 610px; }
    }
</style>
