<template lang="pug">
  .process.wrapper
    v-icon.process__close(size="40", @click="handleClickClose") mdi-close
    transition(name="fade", mode="out-in")
      section(v-if="!connected && !steps[0] && !steps[1] && !steps[2] && !steps[3]")
        .process_content
          .process_title {{ $t('messages.inform.choose2FATitle') }}
          .process_description(v-html="$t('messages.inform.choose2FADesc')")
          .process_cards
            .card(@click="choose2FAType(1)")
              img(src="../assets/images/g_auth.png" width="40" height="40")
              p {{ $t('common.googleAuthenticator') }}
              v-switch.mobile(readonly, color="#19a2dc", hide-details, :ripple="false")
            .card(@click="choose2FAType(2)")
              SvgMail
              p {{ $t('common.email2FA') }}
              v-switch.mobile(readonly, color="#19a2dc", hide-details, :ripple="false")
    transition(name="fade", mode="out-in")
      section(v-if="steps[0] && !steps[1] && !steps[2] && !steps[3]")
        .process_content
          .process_title {{ $t('2FA.whatIsTitle.' + (twoFAType || user2fa_type)) }}
          .process_description(v-html="$t('2FA.whatIsDesc.' + (twoFAType || user2fa_type))")
          v-checkbox(
            v-if="connected",
            v-model="disconnectConfirm",
            :rules="[rules.required]")
            template(v-slot:label="")
              span {{ $t('2FA.disconnectConfirm') }}
          .process_action(v-if="!connected && twoFAType === 1")
            .download_links
              a.main_link(:href="downloadLinks.iOS", target="_blank")
                SvgApple.main_link_img
                | {{ $t('actions.downloadAuthenticator', { OS: 'iOS' }) }}
              a.main_link(:href="downloadLinks.android", target="_blank")
                SvgAndroid.main_link_img
                | {{ $t('actions.downloadAuthenticator', { OS: 'Android' }) }}
          //- v-form(v-if="!connected && twoFAType === 2", v-model="passwordValid", @submit.prevent="")
          //-   v-text-field(
          //-     :label="$t('formFields.passwordCurrent')",
          //-     type="password",
          //-     v-model="password",
          //-     :rules="[rules.required, rules.passwordLength]")
          .process_action
            v-btn(
              depressed,
              @click="connected ? proceedNext(2) : proceedNext(1)", :disabled="connected ? !disconnectConfirm : false") {{ proceedBtnText }}
    transition(name="fade", mode="out-in")
      section.process_qr__section(v-if="!connected && steps[0] && steps[1] && !steps[2] && !steps[3] && secretKey")
        .process_content
          .process_title.mobile {{ $t('common.googleAuthenticator') }}
          .process_qr__wrap
            .process_qr__img.second
              qrcode-vue(:value="qrCodeURI", :size="200", level="H")
            .process_description.first {{ $t('hints.scanQR') }}
          .process__hint(v-html="$t('hints.enable2FAManually', { email, key: secretKey })")
          .process_action
            v-btn.process_qr__action(depressed, @click="proceedNext(2)") {{ $t('hints.enterCode') }}
    transition(name="fade", mode="out-in")
      section(v-if="steps[0] && steps[1] && steps[2] && !steps[3]")
        .process_content
          .process_title {{ $t('2FA.codeInputTitle.' + (twoFAType || user2fa_type)) }}
          .process_verification.second
            .process_verification__wrap
              SecureCodeInputs(
                v-model="code",
                :errors="errors",
                :twoFAType="twoFAType || user2fa_type",
                :email="email",
                blurOnComplete)
    transition(name="fade", mode="out-in")
      section(v-if="steps[0] && steps[1] && steps[2] && steps[3] && !loadingProfile")
        .process_content.final
          .mobile.check
            v-icon(color="black", size="42") mdi-check
          .process_title {{ connected ? $t('2FA.successConnected.' + twoFAType) : $t('2FA.successDisconnected.' + user2fa_type) }}!
          .process_description(v-if="connected") {{ $t('2FA.finalDesc') }}
          .process_action
            v-btn.white.shadowed(depressed, @click="handleClickClose") {{ $t('actions.goBackToSecuritySettings') }}
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import SecureCodeInputs from '@/components/SecureCodeInputs';
import SvgPlus from '@/components/svg/SvgPlus';
import SvgArrowRight from '@/components/svg/SvgArrowRight';
import SvgMail from '@/components/svg/SvgMail';
import SvgAndroid from '@/components/svg/SvgAndroid';
import SvgApple from '@/components/svg/SvgApple';

import CONSTANTS from '@/constants';

export default {
  components: {
    SvgPlus,
    SvgArrowRight,
    SecureCodeInputs,
    SvgMail,
    SvgAndroid,
    SvgApple,
    QrcodeVue
  },
  props: {
    connected: { type: Boolean, required: true }
  },
  data() {
    return {
      passwordValid: false,
      scrollOptions: {
        easing: 'easeOutCubic',
        duration: 900,
        offset: 0
      },
      steps: {
        0: this.connected,
        1: false,
        2: false,
        3: false
      },
      errors: [],
      code: '',
      secretKey: '',
      downloadLinks: CONSTANTS.AUTHENTICATOR_LINKS,
      twoFAType: null,
      password: '',
      loading: false,
      clearErrorTimerId: null,
      disconnectConfirm: false
    };
  },
  methods: {
    choose2FAType(type) {
      this.steps[0] = true;
      this.twoFAType = type;
    },
    handleClickClose() {
      this.$emit('clickClose');

      this.steps = {
        0: this.connected,
        1: false,
        2: false,
        3: false
      };

      this.twoFAType = null;
      this.errors = [];
      this.code = '';
    },
    proceedNext(stepNumber) {
      if (stepNumber === 1 && !this.connected) {
        if (this.twoFAType === 1) {
          this.get2FASecret();
        } else {
          this.steps[1] = true;
          this.steps[2] = true;
        }
      } else {
        this.steps[1] = true;
        this.steps[stepNumber] = true;
      }
    },
    get2FASecret() {
      this.$store.dispatch('user/get2FAsecret')
        .then(res => {
          this.secretKey = res.id;
          this.steps[1] = true;
        })
        .catch(error => console.log('Error in getting QR code:', error));
    },
    toggle2FA() {
      const payload = {
        data: {
          type: 'toggle-2fa',
          attributes: {
            is_enabled: !this.connected,
            code: this.code,
            '2fa_type': this.connected ? this.user2fa_type : this.twoFAType
          }
        }
      };

      this.$store.dispatch('user/toggle2FA', payload)
        .then(() => {
          this.errors = [];
          this.proceedNext(3);
          if (!this.$store.state.user.loadingProfile) this.$store.dispatch('user/getUser');
        })
        .catch(errors => { this.errors = errors; });
    }
  },
  watch: {
    code(val) {
      if (val.length === 6) this.toggle2FA();
    }
  },
  computed: {
    email() { return this.$store.state.user.email; },
    user2fa_type() { return this.$store.state.user['2fa_type']; },
    loadingProfile() {
      return this.$store.state.user.loadingProfile;
    },
    userOS() {
      if (/android/i.test(navigator.userAgent)) return 'android';

      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) return 'iOS';

      return undefined;
    },
    qrCodeURI() {
      return `otpauth://totp/${this.email}?secret=${this.secretKey}&issuer=KickEcosystem`;
    },
    rules() {
      return {
        required: v => !!v || this.$t('validation.required'),
        passwordLength: v => v.length >= 8 || this.$t('validation.passwordLength', { min: 8 })
      };
    },
    proceedBtnText() {
      if (this.connected) return this.$t(`2FA.disconnect2FA.${this.user2fa_type}`);

      return this.$t(`2FA.connect2FA.${this.twoFAType}`);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  section {
    text-align: left;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 610px;
    margin: auto;

    @media screen and (max-width: 660px) { height: 100%; }
  }

  .process {
    text-align: left;
    max-width: 1080px;
    margin: auto;

    a {  font-size: 20px !important; }

    .mobile { display: none; }

    &__close {
      z-index: 100;
      outline: none;
      cursor: pointer;
      position: absolute;
      right: 45px;
      top: 45px;
    }

    &_title {
      line-height: 40px;
      font-size: 35px;
      color: $sub-black;
      font-weight: bold;
      margin-bottom: 28px;
      text-align: center;
      @media screen and (max-width: 660px) { font-size: 28px; }
    }

    &_description {
      color: $sub-black;
      line-height: 32px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 30px;

      @media screen and (max-width: 660px) { font-size: 16px; }
    }

    &_content {
      max-width: 600px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      text-align: center;
      .download_links {
        margin-top: 20px;
        margin-bottom: 30px;

        & > a {
          display: inline-flex;
          margin: 8px 0;
          align-items: flex-start;
          margin-right: 30px;

          & > svg { margin-right: 8px; }
        }
      }

      .check {
        width: 100px;
        height: 100px;
        border-radius: 30px;
        background-color: $grey-light;
        margin: 0 auto;

        .v-icon { margin: 0 auto; }
      }
    }

    &_link {
      color: $blue;
      font-size: 20px;
      line-height: 25px;
      font-weight: bold;
      text-decoration: none;
      margin-bottom: 40px;
      cursor: pointer;

      @media screen and (max-width: 660px) { font-size: 16px; }

      &:hover {
        filter: brightness(1.2);
        & > svg { transform: translateX(7px); }
      }

      & > svg {
        margin-right: 12px;
        max-width: 14px;
        transition: all .3s $easing;
        ::v-deep path { fill: $blue; }
      }
    }

    &_side {
      width: 100%;
      max-width: 233px;

      &__title {
        font-size: 16px;
        color: $sub-black;
        font-weight: 600;
        margin-top: 34px;
      }
    }

    &_qr {

      &__section .process_content { max-width: 720px; }

      &__wrap {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media screen and (max-width: 660px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__img {
        max-width: 200px;
        margin-right: 60px;

        @media screen and (max-width: 660px) { margin: auto; }
      }

      &__action {
        margin: 20px 0;

        button { font-size: 20px !important; }

        ::v-deep strong {
          font-weight: 600 !important;
          margin-left: 20px;
        }
      }
    }

    &_cards {
      display: flex;
      justify-content: space-between;

      .card {
        cursor: pointer;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 8px 22px $light-theme-08, 0px 0px 6px $light-theme-05;
        width: calc(50% - 10px);
        text-align: center;
        font-size: 20px;
        font-weight: 700;

        p {
          max-width: 200px;
          margin: 0 auto;
        }
      }

      .v-input--switch {
        margin-top: 0 !important;
        padding-top: 0 !important;
        margin-left: auto;

        ::v-deep .v-input--switch__track {
          height: 6px !important;
          top: calc(50% - 3px) !important;
        }
      }
    }

    &_error {
      color: $error-color;
      margin-bottom: 30px !important;
      font-size: 20px;
    }

    &_verification__wrap { margin-bottom: 41px; }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all .3s $easing;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @media screen and (max-width: 600px) {
    .process {
      font-weight: 400;
      display: flex;
      flex-direction: column;

      .mobile { display: flex; }

      .first { order: 1 !important; }
      .second { order: 2 !important; }
      .third { order: 3 !important; }

      a {  font-size: 16px !important; }

      &.wrapper { margin-top: 0; }

      &__close {
        top: 20px;
        right: 20px;
      }

      &_title {
        font-size: 20px;
        margin-bottom: 15px;
      }

      &_description {
        font-weight: 400;
        line-height: 1.3;
      }

      &_content {
        justify-content: flex-start;
        padding-top: 50px;

        &.final {
          justify-content: center;
          text-align: center;

          .process_action { margin-top: 40px; }
        }
      }

      &_action {
        button { width: 100%; }
      }

      &_cards {
        position: relative;
        flex-wrap: wrap;
        padding-top: 10px;

        &::before {
          position: absolute;
          content: '';
          width: calc(100% + 40px);
          top: 0;
          left: -20px;
          height: 10px;
          background-color: $grey-light16;
          border-top: 1px solid $border-light-grey;
          border-bottom: 1px solid $border-light-grey;
        }

        .card {
          border-radius: 0;
          box-shadow: none;
          width: 100%;
          border-bottom: 1px solid $border-light-grey;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 600;
          padding-left: 0;
          padding-right: 0;

          .v-input--switch { display: flex; }

          p {
            max-width: unset;
            margin: 0 5px 0 0;
            font-size: 16px;
            text-align: left;
          }

          svg {
            min-width: 16px;
            width: 16px !important;
            margin-right: 20px;

            ::v-deep rect,
            ::v-deep path {
              stroke-width: 3px !important;
            }
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 15px;
          }
        }
      }

      &_qr__img {
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }
  }
</style>
